import { baseURL, Headers } from "./apis";
import axios, { Axios } from "axios";
import { Dispatch } from "react";

export const SignInUser = async (state) => {
	const res = await axios.get("https://api.ipify.org/?format=json");
    state.ip = res.data.ip 
	const response = await axios.post(baseURL + "/user-check", state, Headers)
	return response.data;
};
export const UpdateUser = async (state) => {
	await axios.post(baseURL + "/users/update", state, Headers).then(async (res) => {
		
	});
};
export const PostReview = async (state) => {
	const res = await axios.post(baseURL + "/comment", state, Headers);
};
export const GetUserReview = async (state) => {
	const res = await axios.post(baseURL + "/user-comments", state, Headers);
};
