
//export const baseURL='http://localhost:3003';
export const baseURL='https://api.tipsterscheck.com';
export const Apis ={
    users:baseURL+ '/users',
}
export const Headers = {
      Accept: "application/json",
      "Content-type": "application/json",
}
