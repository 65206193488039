import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Header from "../../components/Header/header";
import "../../assets/css/style.css";
import "../../App.css";

import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";

export default function Reseasoffline() {
	const { t, i18n } = useTranslation();
	const reseasoffline  = t("reseasoffline", { returnObjects: true });
	// const [i, seti] = useState(1);
	// const [j, setj] = useState(1);
	var i = 0;
	var j = 0;

	return (
		<div>
			<Header />
      <style>
        {
          `
		  a{
			text-decoration: none
		  }
		  .c7 {
			color: #000000;
			font-weight: 700;
			text-decoration: none;
			vertical-align: baseline;
			font-size: 11pt;
			font-family: "Arial";
			font-style: normal;
		}.c19, .c8 {
			text-decoration-skip-ink: none;
			-webkit-text-decoration-skip: none;
			text-decoration: underline;
		}
          `
        }
      </style>
			<div className="top-banner">
				<div className="container">
					<h2>{t("Offline reviews")}</h2>
					{/* <p> Frequently Asked Questions </p> */}
				</div>
			</div>
			{/*  ================= LOWER SECTION ======================== */}
			<div className="container mt-5 gx-3">
				<div className="section-privacy">
					<h2>{t("")}</h2>
					<div className="sub-section-privacy" dangerouslySetInnerHTML={{__html: reseasoffline }}></div>
				</div>
			</div>
			<div className="py-5"></div>
			<Footer />
		</div>
	);
}
