import LogoSM from "../../assets/images/Logo-sm.png";
import ScrollToTop from "../../pages/top";
// import EN from "../../assets/images/spain.png";
import { ReactComponent as US } from "../../assets/svg/US_FLAG.svg";
// import SP from "../../assets/images/united-states.png";
import { ReactComponent as EN } from "../../assets/svg/ES_FLAG.svg";
import problem from "../../assets/images/problem.png";
import styles from "../../assets/css/splashScreen.module.css";
import React, { useEffect, useRef, useState, useContext } from "react";
import detectBrowserLanguage from "detect-browser-language";
import LanguageDetector from "i18next-browser-languagedetector";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseURL } from "../../configapi/apis";
import { Button, Container, Form, FormControl, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../../App.css";
import "./header.css";
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import Menu from "../../pages/menu/Menu";
import { useTranslation } from "react-i18next";
import {
	NAVIGATE,
	USER_ID,
	ALL_TIPSTERS,
	ADVANCE_SEARCH,
	updateSearch,
	LOG_OUT,
} from "../../redux/authReducer";
import defaultTipster from "../../assets/images/defaultTipster.png";
import { SignInUser } from "../../configapi/userService";
import { RESET } from "../../redux/user_reducer";

var accents = require("remove-accents");
const fbAppId = "1256001401902872";

export const useUserCheck = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const userCheck = async (data) => {
		if (!data?._id) return;

		let resData = (
			await axios.post(baseURL + "/user-check", { email: data?.email, name: data?.name }, Headers)
		).data;
		
		if (data?._id && !resData.user) {
			window?.FB?.api(`/${fbAppId}/permissions`, "delete", null, (response) => {
				window?.FB?.logout((response) => {
					console.log("Response After logout:", response);
				});
				console.log("Response After logout:", response);
			});

			axios.post(`${baseURL}/user/logout`, {
				id: data?._id,
				newArrival: false,
			});
			dispatch({
				type: LOG_OUT,
			});
			dispatch({
				type: RESET,
			});
			localStorage.clear();

			//history("/");
		}
	};

  return {userCheck}
};

function Header() {
	const [scrol, setScroll] = useState("");
	const { pathname } = useLocation();
	const { t, i18n } = useTranslation();
	const [searchTerm, setSearchterm] = useState("");
	const alltipsters = useSelector((state) => state.authReducer.alltipsters);
	const userprofiledata = useSelector((state) => state.user_reducer);
	const dispatch = useDispatch();
	const history = useNavigate();
	const [toggle, setToggle] = useState(true);
	const userprofile = useSelector((state) => state.user_reducer);
	const [filterArray, setFilteArray] = useState([]);
	const { email, username, userimage, user_id } = userprofile;

	const authCredentials = useSelector((state) => state.authReducer.signInCredentials);
	const location = useLocation();
	const searchInputRef = useRef(null);
  const {userCheck} = useUserCheck()
	useEffect(() => {
		const [first, last] = detectBrowserLanguage();
		const preferedLanguage = {language : "Spanish"} // JSON.parse(localStorage.getItem("newData"));
		const userSelectionLanguage = localStorage.getItem("Language");
   
		if (authCredentials?.email) {
			userCheck(authCredentials);
		}

		if (userSelectionLanguage) {
			if (userSelectionLanguage === "es") {
				handleClick("es");
			} else {
				handleClick("en");
			}
		} else if (preferedLanguage) {
			if (preferedLanguage.language == "Spanish") {
				handleClick("es");
			} else {
				handleClick("en");
			}
		} else {
			if (`${first}${last}` == "es") {
				handleClick("es");
			} else if (detectBrowserLanguage() != "es") {
				handleClick("en");
			}
		}
		if (pathname === "/advancesearch") {
			searchInputRef.current.focus();
		} else {
			if (pathname.slice(0, 9) !== "/tipster/") {
				dispatch(updateSearch(""));
			}
		}
	}, [authCredentials, alltipsters]);

	useEffect(() => {
		axios.get(`${baseURL}/getAllTipsterBySport`).then((res) => {
			setFilteArray(res.data);
		});
	}, [scrol]);

	const onC = () => {
		document.body.style.overflowY = "hidden";
		setToggle((state) => !state);
		// setChe(true);
	};
	const onClose = () => {
		document.body.style.overflowY = "scroll";

		setToggle((state) => !state);
		// setChe(true);
	};
	const handleClick = (lang) => {
		i18n.changeLanguage(lang);
		//here

		localStorage.setItem("Language", lang);
		// i18n.changeLanguage("en");
	};
	// const storagee = localStorage.getItem("Language")
	//   ? localStorage.getItem("Language")
	//   : null
	// const storagee = localStorage.getItem("Language");
	// useEffect(() => {
	//   if(window.innerHeight===722 && window.innerWidth===1042){
	//       window.location.reload()
	//     }
	// },[])
	if (toggle) {
		return (
			<div className="header-main ">
				{/* <ScrollToTop />   */}
				<div className="container-fluid header-padding">
					<div className="row d-flex justify-content-between">
						<div
							className={
								pathname == "/"
									? "col-1 d-flex align-items-center"
									: "col-1 d-flex align-items-center"
							}
						>
							<div className="header-logo">
								<Link to="/">
									<img class="tipster-logo-size" src={LogoSM} />
								</Link>
							</div>
						</div>
						<div
							className={
								pathname == "/"
									? "col-sm-7 d-none d-lg-flex flex-fill d-flex justify-content-around align-items-center"
									: "col-sm-8 col-lg-8  col-md-6 col-sm-4 d-none d-md-flex flex justify-content-between align-items-center"
							}
						>
							<div
								className={
									pathname == "/"
										? ["col-12 d-flex", "align-items-center", "justify-content-end", "mx-5"].join(
												" "
										  )
										: ["col-12 d-flex", "align-items-center", "justify-content-between"].join(" ")
								}
							>
								{pathname == "/" ? (
									<></>
								) : (
									<div id="search_div col-3">
										<input
											ref={searchInputRef}
											id="search-field"
											type="text"
											className="searchboxtop d-none d-sm-block"
											placeholder={t("Search Tipsters")}
											value={searchTerm}
											onChange={(e) => {
												setSearchterm(e.target.value);
												dispatch(updateSearch(e.target.value));
											}}
										></input>
										{document.activeElement === document.getElementById("search-field") &&
											searchTerm !== "" && (
												<div
													style={{
														position: "absolute",
														margin: "5px 0px",
														zIndex: 9999,
													}}
												>
													<div className="mainboxtop" style={{ overflowY: "scroll" }}>
														<p
															style={{
																fontSize: "14px",
																fontWeight: "600",
																textAlign: "left",
															}}
														>
															{t("Tipsters")}
														</p>

														{filterArray.filter((val) => {
															if (searchTerm == "") {
																return null;
															} else if (
																accents
																	.remove(val?.name)
																	.toLowerCase()
																	.includes(accents.remove(searchTerm.toLowerCase())) &&
																val.isOnline === true
															) {
																return val;
															}
														}).length > 0 ? (
															filterArray
																.filter((val) => {
																	if (searchTerm == "") {
																		return null;
																	} else if (
																		accents
																			.remove(val?.name)
																			.toLowerCase()
																			.includes(accents.remove(searchTerm.toLowerCase())) &&
																		val.isOnline === true
																	) {
																		return val;
																	}
																})
																.sort((a, b) => {
																	return b.counterReviews - a.counterReviews;
																})
																.map((val, key) => {
																	return (
																		<div
																			className="div"
																			onClick={(e) => {
																				e.preventDefault();
																				history("/tipster/" + val.name.split(" ").join("+"));
																				dispatch({
																					type: USER_ID,
																					payload: {
																						name: val.name,
																						stars: val.stars,
																						counterReviews: val.counterReviews,
																						picture: val.picture,
																						id: val._id,
																						countryFlag: val.countryFlag,
																						sportsImage: val.sportsImage,
																						verification: val.verified ? val.verified : null,
																						traceability: val.traceability,
																						transparency: val.transparency,
																						description: val.description,
																						faqs: val.faqs,
																						link: val.links,
																					},
																				});
																				setSearchterm("");
																				window.location.reload();
																			}}
																			style={{
																				display: "flex",
																				justifyContent: "flex-start",
																				gap: "10px",
																				margin: "15px 0px",
																			}}
																		>
																			<div>
																				<img
																					referrerpolicy="no-referrer"
																					style={{
																						height: "40px",
																						width: "40px",
																						borderRadius: "50px",
																					}}
																					src={!val.picture ? defaultTipster : val.picture}
																				></img>
																			</div>

																			<div>
																				<p
																					style={{
																						cursor: "pointer",
																						marginBottom: "0px",
																						fontSize: "13px",
																						fontWeight: "500",
																					}}
																				>
																					{val.name}
																					<img
																						referrerpolicy="no-referrer"
																						style={{
																							height: "20px",
																							width: "20px",
																							margin: "0px 5px 0px 5px",
																						}}
																						src={val.countryFlag}
																					/>
																				</p>
																				<p
																					style={{
																						textAlign: "left",
																						fontSize: "10px",
																						marginBottom: "0px",
																					}}
																				>
																					{val.sports}
																				</p>
																			</div>
																		</div>
																	);
																})
														) : (
															<div>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																	}}
																>
																	<img
																		referrerpolicy="no-referrer"
																		src={problem}
																		width="70px"
																		height="70px"
																	/>
																</div>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																	}}
																>
																	<h5>{t("We couldn't find any results for your search")}.</h5>
																</div>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																	}}
																>
																	<p>
																		{t(
																			"Try another name or fill in a quick form for us to add the profile of the tipster you are looking for"
																		)}
																		.
																	</p>
																</div>

																<button
																	className={styles.btnmodal}
																	onClick={() => {
																		i18n.language == "en"
																			? window.open("https://forms.gle/DJZuiwqJ8STv2yWa7")
																			: window.open("https://forms.gle/SZxz8Dpr1vJLnh4y8");
																	}}
																>
																	{t("PROPOSE A TIPSTER")}
																</button>
															</div>
														)}
													</div>
													<div className="advancedsearchtop">
														<p
															style={{
																fontSize: "12px",
																fontWeight: "500",
																textAlign: "left",
																margin: "0px",
															}}
														>
															{t("Not Looking for any Tipster in particular")}?
														</p>
														<button
															className="advancebtntop"
															onClick={() => history("/advancesearch")}
														>
															{t("Advance Search")}
														</button>
													</div>
												</div>
											)}
									</div>
								)}
								<div
									className={
										pathname !== "/"
											? ["menu", "d-none", "d-xl-block", "col-8"].join(" ")
											: ["menu", "d-none", "d-lg-block", "col-10"].join(" ")
									}
								>
									<ul
										style={{
											display: "grid",
											gridTemplateColumns: "0.8fr 0.8fr 0.6fr 0.6fr",
											justifyContent: "space-between",
										}}
									>
										<li style={{ textAlign: "center" }}>
											<NavLink
												exact
												className="navbarButtons"
												activeClassName="activeClass"
												to="/advancesearch"
											>
												{t("Advanced search")}
											</NavLink>
										</li>
										<li style={{ textAlign: "center" }}>
											<NavLink
												className="navbarButtons"
												activeClassName="activeClass"
												to="/propose"
											>
												{t("Propose a tipster")}
											</NavLink>
										</li>
										<li style={{ textAlign: "center" }}>
											<NavLink
												className="navbarButtons"
												activeClassName="activeClass"
												to="/mobileapp"
											>
												{t("Mobile app")}
											</NavLink>
										</li>
										<li style={{ textAlign: "center" }}>
											<NavLink
												className="navbarButtons"
												activeClassName="activeClass"
												to="/fortipster"
											>
												{t("For tipsters")}
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div
							className={
								pathname == "/"
									? "col-sm-3 col-lg-3 col-md-5 col-sm-6 col-7 d-flex align-items-center justify-content-between"
									: "col-8 col-lg-3 col-md-5 col-sm-7 d-flex align-items-center justify-content-between"
							}
							style={{ padding: "0px" }}
						>
							<div className="row col-12 p-0">
								<div className="col-7 d-flex justify-content-around align-items-center">
									<div
										className="toggle-icon"
										onClick={() => {
											setScroll("hidden");
										}}
									>
										<button className="toggle-btn d-flex">
											<span class="material-icons" onClick={onC}>
												&#xe5d2;
											</span>
										</button>
									</div>
									<div className="languge-switch d-flex justify-content-center align-items-center">
										<button className="toggle-btn" onClick={() => handleClick("es")}>
											{/* <img src={EN} /> */}
											<EN width="30" height="30" />
										</button>
										<button className="toggle-btn" onClick={() => handleClick("en")}>
											{/* <img src={SP} /> */}
											<US width="30" height="30" />
										</button>
									</div>
								</div>
								<div
									className="col-5"
									style={{
										display: "flex",
										justifyContent: "end",
										padding: "0px",
									}}
								>
									<div className="signin-btn" style={{ display: "flex" }}>
										{authCredentials?.token ? (
											<img
												referrerpolicy="no-referrer"
												onClick={() => history("/userprofile")}
												src={authCredentials?.imageUrl}
											/>
										) : (
											<button
												id="signinbtn"
												onClick={() => history("/Signin", { state: { redirectTo: location?.pathname } })}
												className="login-btn d-flex justify-content-end"
											>
												{" "}
												{t("Sign In")}
												<span id="icon1" class="material-icons sigin-button-styling">
													&#xea77;
												</span>
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div id="asad" className="fullPage-menu">
				<Menu close={onClose} name="asad" />
			</div>
		);
	}
}
export default Header;
