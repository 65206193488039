import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Header from "../../components/Header/header";
import "../../assets/css/style.css";
import "../../App.css";

import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";

export default function ReportReview() {
	const { t, i18n } = useTranslation();
	const reportReview = t("reportReview", { returnObjects: true });
	// const [i, seti] = useState(1);
	// const [j, setj] = useState(1);
	var i = 0;
	var j = 0;

	return (
		<div>
			<Header />
			{/* <div className="container-fluid top-banner">
        <div className="d-flex flex-column justify-content-md-around ms-5">
          <h2 className="h1 fw-bold top-banner">Terms & Conditions</h2>
          <p className="fs-6 text-light">Frequently Asked Questions</p>
        </div>
      </div> */}
      <style>
        {
          `.c24 {
            text-decoration-skip-ink: none;
            -webkit-text-decoration-skip: none;
            text-decoration: underline;
          }
          .c5 {
			color: #000000;
			font-weight: 700;
			text-decoration: none;
			
		}.c12 {
			color: #6d9eeb;
		}
		  .c7 {
			background-color: #ffffff;
			font-size: 17pt;
			color: #333333;
			font-weight: 700;
		}
		.c4 {
			font-size: 13pt;
			color: #333333;
			font-weight: 700;
		}
		.c13 {
			font-size: 13pt;
			color: #3c78d8;
		}
          `
        }
      </style>
			<div className="top-banner">
				<div className="container">
					<h2>{t("Report a review")}</h2>
					{/* <p> Frequently Asked Questions </p> */}
				</div>
			</div>
			{/*  ================= LOWER SECTION ======================== */}
			<div className="container mt-5 gx-3">
				<div className="section-privacy">
					<h2>{t("")}</h2>
					<div className="sub-section-privacy" dangerouslySetInnerHTML={{__html: reportReview}}></div>
				</div>
			</div>
			<div className="py-5"></div>
			<Footer />
		</div>
	);
}
