import { createStore,applyMiddleware, compose} from "redux";
import postsReducer from "./reducer";
import { combineReducers } from "redux";
import user_reducer from './user_reducer';
import authReducer from './authReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
}

const composeEnhancers =  compose || window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ;
const rootReducer = combineReducers({user_reducer,authReducer});
const persistedReducer = persistReducer(
  persistConfig, 
  rootReducer)
const store = createStore(
  persistedReducer,
  composeEnhancers
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const  persistor = persistStore(store);
console.log("redux_persist");
export  {store, persistor}
