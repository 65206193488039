import React from "react";
import BasePage from "./BasePage";

export default function Routess() {
  return (
    <>
      <BasePage />
    </>
  );
}
