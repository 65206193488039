import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Header from "../../components/Header/header";
import "../../assets/css/style.css";
import "../../App.css";

import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";

export default function GuidelinesTipsters () {
	const { t, i18n } = useTranslation();
	const guidelinesTipsters = t("guidelinesTipsters", { returnObjects: true });
	// const [i, seti] = useState(1);
	// const [j, setj] = useState(1);
	var i = 0;
	var j = 0;

	return (
		<div>
			<Header />
			{/* <div className="container-fluid top-banner">
        <div className="d-flex flex-column justify-content-md-around ms-5">
          <h2 className="h1 fw-bold top-banner">Terms & Conditions</h2>
          <p className="fs-6 text-light">Frequently Asked Questions</p>
        </div>
      </div> */}
      <style>
        {
          `.c36 {
			font-size: 12pt;
			color: #3d85c6;
		}
		.c17 {
			color: #000032;
			font-weight: 700;
			text-decoration: none;
			vertical-align: baseline;
			
		}
		.c26{
			text-decoration: underline;
		}
		.c32 {
			font-weight: 700;
		}
			.c19 {
				color: #3c78d8;
				font-size: 12pt;
			}
		
          `
        }
      </style>
			<div className="top-banner">
				<div className="container">
					<h2>{t("Guidelines for tipsters")}</h2>
					{/* <p> Frequently Asked Questions </p> */}
				</div>
			</div>
			{/*  ================= LOWER SECTION ======================== */}
			<div className="container mt-5 gx-3">
				<div className="section-privacy">
					<h2>{t("")}</h2>
					<div className="sub-section-privacy" dangerouslySetInnerHTML={{__html: guidelinesTipsters}}></div>
				</div>
			</div>
			<div className="py-5"></div>
			<Footer />
		</div>
	);
}
