import "./App.css";
import React, {useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Routess from "./config/routes";
import { gapi } from 'gapi-script'
import { ToastContainer, toast } from 'react-toastify';

function App() {
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: '532097815655-vkj7bn60mo0uesl9d5dhknptt6sdu731.apps.googleusercontent.com',
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const ThemeContext = React.createContext('light');
  return (
    <ThemeContext.Provider>
      <Routess />
      <ToastContainer />
    </ThemeContext.Provider>
  );
}

export default App;

