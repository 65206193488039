import { useEffect,useState } from "react";
import LogoFooter from "../../assets/images/logo-ftr.png";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from "react-router-dom"
import {Button, Container, Form, FormControl, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import "../../App.css";
import "./footer.css"
import axios from "axios"
import { useTranslation } from 'react-i18next';

import { baseURL, Headers } from "../../configapi/apis";
function Footer(props) {
    let { setChe } = props;
    const { t, i18n } = useTranslation();
    const counter = useSelector((state) => state.authReducer.change);
    const [headingTotalReview,setHeadingTotalReviews] = useState(0)
  
    const onC = () => {
        setChe(false);
    };

    useEffect(() => { 
        axios.get(`${baseURL}/getAllTipsterBySport`).then((res) => {
          const totalNumberOfReviews = res.data.reduce((acc, curr) => {
            return acc + curr.counterReviews;
          }, 0);
          setHeadingTotalReviews(totalNumberOfReviews);
        });
      }, [])

    return (
      <div>
        <div className="footer">
          <div className="py-5"></div>
          <div className="py-5"></div>
          <div className="container">
            <div className="row">
              <div style={{ marginTop: "0px" }} className="col-lg-3 col-sm-12">
                <div className="footer-logo">
                  <a href="/">
                    <img src={LogoFooter} />
                  </a>
                  <h2>TipstersCheck</h2>
                  <p>
                    {t(
                      "Find good tipsters and sports betting experts based on"
                    )}{" "}
                    +{headingTotalReview}{" "}
                    {t("reviews from real bettors like you")}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div style={{ marginTop: "5px" }} className="quick-links">
                  <h2>{t("Company")}</h2>
                  <ul>
                    <li>
                      {/* <a>{t("About Tipsters Check")}</a> */}
                      <Link to="/about-tipsterscheck" style={{ textDecoration: "none" }}>
                        {t("About Tipsters Check")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/faqs" style={{ textDecoration: "none" }}>
                        {t("FAQ")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactus" style={{ textDecoration: "none" }}>
                        {t("Contact")}
                      </Link>
                    </li>
                    {/* <li>
                      <a style={{ textDecoration: "none" }} href="https://www.tipstersprime.com/en/blog-tipsters-prime/"  >Blog</a>
                    </li> */}
                    <li>
                      <Link to="/propose" style={{ textDecoration: "none" }}>
                        {t("Propose a tipster")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/fortipster" style={{ textDecoration: "none" }}>
                        {t("For tipsters")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        style={{ textDecoration: "none" }}
                      >
                        {t("Privacy policy")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-conditions"
                        style={{ textDecoration: "none" }}
                      >
                        {t("Terms and conditions")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="quick-links">
                  <h2>{t("Resources")}</h2>
                  <ul>
                    <li>
                      <a  style={{ textDecoration: "none" }} href="/guidelines-reviewers" >{t("Guidelines for reviewers")}</a>
                    </li>
                    <li>
                      <a style={{ textDecoration: "none" }} href="/help-writing-reviews">{t("Help writing reviews")}</a>
                    </li>
                    <li>
                      <a style={{ textDecoration: "none" }} href="/report-a-review"> {t("Report a review")}</a>
                    </li>
                    <li>
                      <a style={{ textDecoration: "none" }} href="/guidelines-for-tipsters">{t("Guidelines for tipsters")}</a>
                    </li>
                    <li>
                      <a style={{ textDecoration: "none" }} href="/juego-responsable">{t("Responsible gambling")}</a>
                    </li>
                    <li>
                      <a style={{ textDecoration: "none" }} href="/all-resources">{t("See all resources")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div style={{ marginTop: "12px" }} className="quick-links">
                  <h2>{t("Find Us")}</h2>
                  <ul>
                    <li>
                      <a
                        className="decoration"
                        rel="nofollow"
                        href="https://twitter.com/TipstersCheck"
                        target="_blank"
                      >
                        {t("Twitter")}
                      </a>
                    </li>
                    <li>
                      <a

                        className="decoration"
                        rel="nofollow"
                        href="https://www.instagram.com/tipsterscheck/"
                        target="_blank"
                      >
                        {t("Instagram")}
                      </a>
                    </li>
                    <li>
                      <a
                        className="decoration"
                        rel="nofollow"
                        href={ i18n.language=='en'? 'https://t.me/TipstersCheckEnglish' : 'https://t.me/TipstersCheck'  } 
                        target="_blank"
                      >
                        {t("Telegram")}
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/TipstersCheckBets" target="_blank" rel="nofollow" className="decoration">Tipsters Check Bets</a>
                    <li>
                    </li>
                      <a
                        className="decoration"
                        rel="nofollow"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.tipstersprime.tipster.reviews"
                      >
                        {t("Download Android app")}
                      </a>
                    </li>

                    <li>
                            
                      <a href="https://apps.apple.com/pk/app/tipsters-check/id1541925197" target="_blank"  className="decoration">{t("Download iOS app")}</a>
                   
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>

        <div className="footer-bottom-bar">
          <p>{t("Tipsters Check | © 2021. All Rights Reserved")}. | v@1.0.3</p>
        </div>
      </div>
    );
        
}
export default Footer;
