import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
  
import "./style.css";
import "../../App.css";
import "../../components/Header/header.css";
import "../../assets/css/style.css";
import Logo2 from "../../assets/images/logo2.png";
import SP from "../../assets/images/spain.png";
import EN from "../../assets/images/united-states.png";
import searchMagnifier from "../../assets/images/searchMagnifier.png";
import ReactDOM from "react-dom";
//import { useTransition, animated, config } from "react-spring";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { USER_ID } from "../../redux/authReducer";
import styles from "../../assets/css/splashScreen.module.css";
import { useTranslation } from 'react-i18next';
import defaultTipster from "../../assets/images/defaultTipster.png"
import problem from '../../assets/images/problem.png'
var accents = require('remove-accents');

function Menu(props) {
  const [scrol , setScroll ] = useState(false);
  const { t, i18n } = useTranslation();
  const [transition, setTransition] = useState(true);
  const [searchterm, setSearchterm] = useState("");
  // const [filterArray, setFilteArray] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { setChe } = props;
  const counter = useSelector((state) => state.authReducer.change);
  const filterArray = useSelector((state) => state.authReducer.alltipsters);
  // console.log("in jhome", counter);
  const onC = () => {
    setChe(false);
  };

  useEffect(() => {
    setTransition(false);
  }, [props.name,scrol]);

  const filterDataArray = filterArray?.filter((val) => {
    if (searchterm == "") {
      return null;
    }
    else if (accents.remove(val.name.toLowerCase()).includes(accents.remove(searchterm.toLowerCase())) && val.isOnline === true) {
      return val;
    }
  })?.sort((a, b) => { return b.counterReviews - a.counterReviews })?.map((val) => {
    if (!val) {
      return <span>{t("Not Found")}</span>
    }
    // console.log("Challenger",val);
    //   {{console.log("newcheck",localStorage.getItem("flag"))}}
    else {
      return (
        <li>
          {console.log( window.location  ,'pathname')}
          <div className="div" style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', margin: '15px 0px' }} onClick={() => {
            navigate("../tipster/" + val.name.replace(" ", "+")); 
            dispatch({
              type: USER_ID, payload:
              {
                name: val.name,
                stars: val.stars,
                counterReviews: val.counterReviews,
                picture: val.picture,
                id: val._id,
                countryFlag: val.countryFlag,
                sportsImage: val.sportsImage,
                verification: val.verified ? val.verified : null,
                traceability: val.traceability,
                transparency: val.transparency,
                description: val.description,
                faqs: val.faqs,
                link: val.links,
                country: val.country,
                email: val.email,
                sports: val.sports
              }
            })
            props.close()
          }}>
            <div>
              <img style={{ height: '35px', width: '35px', borderRadius: '50px' }} src={!val.picture ? defaultTipster : val.picture}></img>
            </div>
            <div>
              <p
                style={{ cursor: 'pointer', marginBottom: '0px', fontSize: '14px', fontWeight: '500' }} >
                {val.name}
                <img style={{ height: '15px', width: '15px', margin: '0px 5px 0px 5px' }} src={val.countryFlag} />
              </p>
              <p style={{ textAlign: 'left', fontSize: '10px', marginBottom: '0px' }}>
                {val.sports}
              </p>
            </div>
          </div>
        </li>
      )
    }
  })

  return (
    <div  className={transition ? "menu-clr " : "menu-bg"   } >
      <div className="container"> 
        <div className="top-head-menu d-flex justify-content-between align-items-center py-2">
          <div className="text-logo">
            <Link to="/">
              {" "}
              <img src={ Logo2} />{/*  here  */ }
            </Link>
          </div>
          <div className="actions d-flex align-items-center justify-content-between">
            <a className="d-flex text-decoration-none text-white">
              <span
                class="material-icons material-icon-custom fullpage-close-button"
                onClick={() => {props.close(); document.body.style.overflowY = 'scroll'}}//here
              >
                &#xe5cd;
              </span>{" "}
            </a>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div
          className="paddtb align-items-center justify-content-center text-center"
          style={{ display: "inline-block" }}
        >
          <input
            type="text"
            className="searchbox"
            placeholder={t("home page Search placeholder")}
            onChange={(e) => {
              setSearchterm(e.target.value);
            }}
          />
          {searchterm !== "" && (
            <div
              style={{ position: "absolute", margin: "5px 0px", zIndex: 99 }}
            >
              <div className="mainbox" style={{ height: "260px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    textAlign: "left",
                  }}
                >
                  {t("Tipsters")}
                </p>
                {filterDataArray.length < 1 ? (
                    <div>
                    <img src={problem} width  ="70px" height="70px" />
                    <h5>
                      {t("We couldn't find any results for your search")}.
                    </h5>
                    <p>
                      {t(
                        "Try another name or fill in a quick form for us to add the profile of the tipster you are looking for"
                      )}
                      .
                    </p>
                    <button
                      className={styles.btnmodal}
                      onClick={() => {navigate("/propose");props.close()}}
                    >
                      {t("PROPOSE A TIPSTER")}
                    </button>
                  </div>
                ) : (
                  <ul
                    style={{
                      height: "200px",
                      overflowY: "scroll",
                      listStyleType: "none",
                      marginLeft: "-20px",
                    }}
                  >
                    {filterDataArray}
                  </ul>
                )}
              </div>
              <div className="advancedsearch">
                <div className="col-7 align-self-center">
                  <p className="advancesearch-text">
                    {t("Not Looking for any Tipster in particular")}?
                  </p>
                </div>
                <div className="col-5 align-self-center text-right">
                  <button
                    className="advancebtn"
                    onClick={() => {navigate("/advancesearch");props.close(); document.body.style.overflowY = "scroll"; }}
                  >
                    {t("Advanced search")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="py-2" />
        <div className="menu-main d-flex flex-column justify-content-center align-items-center">
          <ul
            className={
              transition
                ? "menu-main-animStart"
                : "menu-main-animEnd d-flex flex-column flex-md-row align-items-center"
            }
          >
            {searchterm ? (
              <></>
            ) : (
              <>
                <li onClick={() => {props.close()}}>
                  <Link
                    // to="/aboutus123"
                    to="/about-tipsterscheck"
                    className="text-decoration-none header-menu-pages-items"
                  >
                    {t("About Us")}
                  </Link>
                </li>
                <li onClick={() => {props.close()}}>
                  <Link   className="text-decoration-none header-menu-pages-items" to="/mobileapp">{t("App")}</Link>
                </li>
                <li onClick={() => {props.close()}}>
                  <Link
                    to="/faqs"
                    className="text-decoration-none header-menu-pages-items"
                  >
                    {t("FAQ")}
                  </Link>
                </li>
                <li onClick={() => {props.close()}}>
                  <Link
                    target="_blank"
                    to="https://t.me/TipstersCheckBets"
                    className="text-decoration-none header-menu-pages-items"
                  >
                    {t("Forecasts")}
                  </Link>
                  {/* <a>{t("")}</a> */}
                </li>
                <li onClick={() => {props.close()}}>
                  <Link
                    to="/contactus"
                    className="text-decoration-none header-menu-pages-items"
                  >
                    {t("Contact Us")}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>

        <div className="py-2" />
        {
          <div className="social-menu">
            <ul
              className={
                transition
                  ? "menu-main-animStart-social"
                  : "menu-main-animEnd-social d-flex flex-column align-items-center flex-md-row" //ul-tipsters-page
              }
            >
              <li onClick={() => {props.close()}}>
                <Link to="/advancesearch" className="menu-pages-title">
                  {t("Advanced search")}
                </Link>
              </li>
              <li className="menu-pages-title-li" onClick={() => {props.close();props.close()}}>
                <Link to="/propose" className="menu-pages-title">
                  {t("Propose a tipster")}
                </Link>
              </li>
              <li className="menu-pages-title-li" onClick={() => { props.close() }}>
                <Link to="/fortipster" className="menu-pages-title">
                  {t("For Tipsters")}
                </Link>
              </li>
              <li className="menu-pages-title-li" onClick={() => { props.close() }}>
                <Link to="/all-resources" className="menu-pages-title">
                  {t("Resources")}
                </Link>
              </li>
            </ul>
          </div>
        }

        <div className="social-menu">
          <ul
            className={
              transition
                ? "menu-main-animStart-social"
                : "menu-main-animEnd-social"
            }
          >
            {/* <li>
              <a>{t("Twitter")}</a>
            </li>
            <li>
              <a>{t("Instagram")}</a>
            </li>
            <li>
              <a>{t("Telegram")}</a>
            </li> */}
            <li>
              <a
                className="decoration"
                href="https://twitter.com/TipstersCheck"
                target="_blank"
              >
                {t("Twitter")}
              </a>
            </li>
            <li>
              <a
                className="decoration"
                href="https://www.instagram.com/tipsterscheck"
                target="_blank"
              >
                {t("Instagram")}
              </a>
            </li>
            <li>
              <a
                className="decoration"
                href= {i18n.language == 'es' ?  'https://t.me/TipstersCheck' : 'https://t.me/TipstersCheckEnglish' } 
                target="_blank"
              >
                {t("Telegram")}
              </a>
            </li>
          </ul>
        </div>
        <div className="py-2" />
      </div>
      <div className="bottombar-menu d-flex justify-content-center">
        <p
          className={
            transition
              ? "menu-main-animStart-footer"
              : "menu-main-animEnd-footer"
          }
        >
          {t("© 2023 | Tipster Check | All Right Reserved")}.
        </p>
      </div>
      {/* <div className="bottomimg-menu">
                <img src={MenuBG} />
            </div> */}
    </div>
  );
}
export default Menu;
