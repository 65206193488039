import { SET_SEARCHTERM } from "./authReducer";

export const ADD_EMAIL = 'ADD_EMAIL';
export const ADD_ID = 'ADD_ID';
export const RESET = "RESET";

//action of reduccer
export function add_user_email(data) {
    return {
        type: ADD_EMAIL,
        data,
    }
}
export function add_user_id(data) {
    return {
        type: ADD_ID,
        data,
    }
}
export function set_searchterm(data) {
    return {
        type: ADD_ID,
        data,
    }
}
//initial state

let initialState = {
    email: "",
    userimage: "",
    username: "",
    user_id: "",
    searchTerm: null
}
export default function user_reducer(state = initialState, action) {
    
    switch (action.type) {
        case ADD_EMAIL:
            return {
                ...state,
                email: action.data.email,
                userimage: action.data.imageUrl,
                username: action.data.name,
            }
        case ADD_ID:
            return {
                ...state,
                user_id: action.data,
            }
        case SET_SEARCHTERM:
            return {
                ...state,
                searchTerm: action.data,
            }
        case RESET:
            return {
                ...state,
                email: "",
                userimage: "",
                username: "",
                user_id: ""
            }
        default: return state
    }
}