import Loading from "../../assets/images/loading_gif.gif"
import Logo from "../../assets/images/splashScreenHome.png"
import styles from "../../assets/css/splashScreen.module.css"
import { useLocation } from 'react-router-dom';

export default function SplashScreen() {
  let location = useLocation();
  return (
    (location?.pathname === '/') ? (
      <div className={styles.container}>
        <img src={Logo} className={styles.loading} style={{borderRadius: '25px'}} />
      </div>
    ) : (
      <div className={styles.container}>
        <img src={Loading} className={styles.loading} />
      </div>
    )
  )
}
