import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Header from "../../components/Header/header";
import "../../assets/css/style.css";
import "../../App.css";

import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";

export default function DefamatoryContent() {
	const { t, i18n } = useTranslation();
	const defamatoryContent = t("defamatoryContent", { returnObjects: true });
	// const [i, seti] = useState(1);
	// const [j, setj] = useState(1);
	var i = 0;
	var j = 0;

	return (
		<div>
			<Header />
			{/* <div className="container-fluid top-banner">
        <div className="d-flex flex-column justify-content-md-around ms-5">
          <h2 className="h1 fw-bold top-banner">Terms & Conditions</h2>
          <p className="fs-6 text-light">Frequently Asked Questions</p>
        </div>
      </div> */}
      <style>
        {
          `
		  .c9 {
			color: #00acee !important;
			font-size: 12pt;
		}
		  .c11 {
			font-weight: 700;
		}
		.c10 {
			font-size: 12pt;
		}
		.c2 {
			text-decoration-skip-ink: none;
			font-size: 12pt;
			-webkit-text-decoration-skip: none;
			
			text-decoration: underline;
		}
		.c3 {
			font-weight: 700;
			text-decoration: none;
			vertical-align: baseline;
			font-size: 12pt;
			font-family: "Arial";
			font-style: normal;
		}
		
		.c0 {
			background-color: #ffffff;
			padding-top: 0pt;
			padding-bottom: 0pt;
			line-height: 1.15;
			orphans: 2;
			widows: 2;
			text-align: left;
		}
          `
        }
      </style>
			<div className="top-banner">
				<div className="container">
					<h2>{t("Defamatory content")}</h2>
					{/* <p> Frequently Asked Questions </p> */}
				</div>
			</div>
			{/*  ================= LOWER SECTION ======================== */}
			<div className="container mt-5 gx-3">
				<div className="section-privacy">
					<h2>{t("")}</h2>
					<div className="sub-section-privacy" dangerouslySetInnerHTML={{__html: defamatoryContent}}></div>
				</div>
			</div>
			<div className="py-5"></div>
			<Footer />
		</div>
	);
}
