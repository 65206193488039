export const CHANGE_DATA = "CHANGE_DATA";
export const NAVIGATE = "NAVIGATE";
export const USER_ID = "USER_ID";
export const SIGN_INCREDENTIALS = "SIGN_INCREDENTIALS";
export const TOKEN_INCREDENTIALS = "TOKEN_INCREDENTIALS";
export const LOG_OUT = "LOG_OUT";
export const HANDLE_REPLY = "HANDLE_REPLY";
export const HANDLE_WRITE_REVIEW = "HANDLE_WRITE_REVIEW";
export const HANDLE_DELETE_REVIEW = "HANDLE_DELETE_REVIEW";
export const SPORT_NAME = "SPORT_NAME";
export const SPORT_EMPTY = "SPORT_EMPTY";
export const ALL_TIPSTERS = "ALL_TIPSTERS";
export const ADVANCE_SEARCH = "ADVANCE_SEARCH";
export const SET_SEARCHTERM = "SET_SEARCHTERM"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const NEW_DATA = "NEW_DATA"
export const ADVANCE_SEARCH_VISITED = "ADVANCE_SEARCH_VISITED"
export const ADVANCE_SEARCH_SPORTS = "ADVANCE_SEARCH_SPORTS"
export const ADVANCE_SEARCH_COUNTRY = "ADVANCE_SEARCH_COUNTRY"
export const ADVANCE_SEARCH_LANGUAGE = "ADVANCE_SEARCH_LANGUAGE"
export const ADVANCE_SEARCH_VERIFIED = "ADVANCE_SEARCH_VERIFIED"
export const FILTER_SORT_ADVANCE_SEARCH = "FILTER_SORT_ADVANCE_SEARCH"
export const CLEAR_ADVANCE_SEARCH_TIME = "CLEAR_ADVANCE_SEARCH_TIME"
export const COMING_FROM_HOME_PAGE = "COMING_FROM_HOME_PAGE"
export const ADVANCE_SEARCH_TIME_OUT = "ADVANCE_SEARCH_TIME_OUT"
export function changedata(data) {
  return {
    type: CHANGE_DATA,
    data,
  };
}

export function updateSearch(data) {
  return {
    type: SET_SEARCHTERM,
    payload: {
      searchItem: data
    },
  };
}

let statee = {
  change: null,
  navigate: null,
  reviewCredentials: null,
  signInCredentials: null,
  token: null,
  reply: null,
  handleWriteReview: null,
  handleReviewDelete: null,
  sportname: null,
  sportnameempty: null,
  alltipsters: null,
  advanceSearch: true,
  searchTerm: null,
  language: null,
  newData: null,
  advanceSearchVisited: false,
  advanceSearchSports: [],
  advanceSearchCountry: [],
  advanceSearchLanguage: [],
  verifiedNotVerified: null,
  advanceSearchFilterSort: null,
  comingFromHomepage: false,
  advancedSearchTimeUpdate : ""
};
var L = ""
export default function authReducer(state = statee, action) {
  switch (action.type) {
    case CHANGE_DATA:

      return {
        ...state,
        change: action.data,
      };
    case NAVIGATE:
      return {
        ...state,
        navigate: action.payload,
      };
    case USER_ID:

      return {
        ...state,
        reviewCredentials: action.payload,
      };
    case SPORT_NAME:
      return {
        ...state,
        sportname: action.payload,
      };
    case SPORT_EMPTY:
      return {
        ...state,
        sportnameempty: null,
      };
    case SET_LANGUAGE:
      // console.log("LLLLLL", action.payload)
      return {
        ...state,
        language: action.payload
      }
    case SIGN_INCREDENTIALS:
      // let temp = signInCredentials
      // temp.language = action.payload
      return {
        ...state,
        signInCredentials: action.payload,
      };
    case TOKEN_INCREDENTIALS:
      return {
        ...state,
        token: action.payload,
      };
    case LOG_OUT:
      return {
        ...state,
        change: null,
        navigate: null,
        reviewCredentials: null,
        signInCredentials: null,
        token: null
      }
    case HANDLE_REPLY:
      return {
        ...state,
        reply: action.payload,
      }
    case HANDLE_WRITE_REVIEW:
      return {
        ...state,
        handleWriteReview: action.payload
      }
    case HANDLE_DELETE_REVIEW:
      return {
        ...state,
        handleReviewDelete: action.payload
      }
    case ALL_TIPSTERS:
      return {
        ...state,
        alltipsters: action.payload
      }
    case ADVANCE_SEARCH:
      return {
        ...state,
        advanceSearch: action.payload
      }
    case SET_SEARCHTERM:
      return {
        ...state,
        searchTerm: action.payload.searchItem
      }
    case NEW_DATA:
      return {
        ...state,
        newData: action.payload
      }
    case ADVANCE_SEARCH_VISITED:
      return {
        ...state,
        advanceSearchVisited: action.payload
      }
    case ADVANCE_SEARCH_SPORTS:
      console.log('Console_from_Reducer_actions', action.payload)
      return {
        ...state,
        advanceSearchSports: action.payload
      }
    case ADVANCE_SEARCH_COUNTRY:
      return {
        ...state,
        advanceSearchCountry: action.payload
      }
    case ADVANCE_SEARCH_LANGUAGE:
      return {
        ...state,
        advanceSearchLanguage: action.payload
      }
    case ADVANCE_SEARCH_VERIFIED:
      console.log("in")
      return {
        ...state,
        verifiedNotVerified: action.payload
      }
    case FILTER_SORT_ADVANCE_SEARCH:
      return {
        ...state,
        advanceSearchFilterSort: action.payload
      }
    case CLEAR_ADVANCE_SEARCH_TIME:
      return {
        ...state,
        advanceSearchFilterSort: action.payload
      }
    case COMING_FROM_HOME_PAGE:
      return {
        ...state,
        advanceSearchFilterSort: action.payload
      }
    case ADVANCE_SEARCH_TIME_OUT:
      return {
        ...state,
        advancedSearchTimeUpdate: action.payload
      }
    default:
      return state;
  }
}
