import React, { Suspense } from "react";
// import { useSelector } from "react-redux";
import { Redirect, Route, Routes } from "react-router-dom";
// import ProtectingRoute from "./ProtectingRoute";
import history from "../history";
import { useSelector } from "react-redux";
import AboutTipsterscheck from "../pages/AboutTipsterscheck";
import GuidelinesReviewers from "../pages/GuidelinesReviewers ";
import HelpWritingReviews from "../pages/HelpWritingReviews";
import ReportReview from "../pages/ReportReview";
import GuidelinesTipsters from "../pages/GuidelinesTipsters";
import AllResources from "../pages/AllResources";
import InformationTipstersProfile from "../pages/InformationTipstersProfile";
import DefamatoryContent from "../pages/DefamatoryContent";
import FactualDisputes from "../pages/FactualDisputes";
import KnowledgeQuiz from "../pages/KnowledgeQuiz";
import SportsBettingQuiz from "../pages/SportsBettingQuiz ";
import RemoveAnyTipster from "../pages/RemoveAnyTipster";
import Reseasoffline from "../pages/Reseasoffline";
import JuegoResponsable from "../pages/JuegoResponsable";

const Home = React.lazy(() => import("../pages/home"));
const AdvanceSearch = React.lazy(() => import("../pages/advance-search/AdvanceSerach"));
const ProposeTipster = React.lazy(() => import("../pages/propose-tipster/ProposeTipster"));
const ForTipster = React.lazy(() => import("../pages/for-tipster/ForTipsters"));
const MobileApp = React.lazy(() => import("../pages/mobile-app/MobileApp"));
const SignIn = React.lazy(() => import("../pages/auth/SignIn"));
const Notfound = React.lazy(() => import("../pages/404"));

const Welcome = React.lazy(() => import("../pages/auth/Welcome"));
const BettingKnowledge = React.lazy(() => import("../pages/auth/BettingKnowledge"));
const QuestionsPage = React.lazy(() => import("../pages/auth/Questions"));
const Menu = React.lazy(() => import("../pages/menu/Menu"));
const TipsterPage = React.lazy(() => import("../pages/tipster-page/TipsterPage"));
const ContactUs = React.lazy(() => import("../pages/contact-us/ContactUs"));
const Faqs = React.lazy(() => import("../pages/Faqs"));
const UserProfile = React.lazy(() => import("../pages/user-profile/UserProfile"));
const SplashScreen = React.lazy(() => import("../components/SplashScreen"));
const AboutUs = React.lazy(() => import("../pages/AboutPage"));
const PrivacyPolicy = React.lazy(() => import("../pages/privacyPrivacy"));
const TermsAndConditions = React.lazy(() => import("../pages/termsConditions"));
const OtherUserProfile = React.lazy(() => import("../pages/OtherUserProfile/OtherUserProfile"));

// const isAuth = false;
// =========================================== Protect Routes ================================
function WelcomeProtected() {
	return (
		//  <ProtectingRoute>
		<Welcome />
		//  </ProtectingRoute>
	);
}
function UserProfileRoute() {
	return (
		//  <ProtectingRoute>
		<UserProfile />
		//  </ProtectingRoute>
	);
}
// ======================================================================================
///////////get local Storage Data //////////
const currentUser = localStorage.getItem("currentUser")
	? JSON.parse(localStorage.getItem("currentUser"))
	: null;
/////////////////////////////////// ============ ///////////////////////////////////
export default function BasePage() {
	const counter = useSelector((state) => state.authReducer.navigate);
	// const { change } = props;
	// const setChe = (state) => {
	//   change(state);
	// };
	return (
		<Routes>
			<Route exact path="/" element={<Home />} />

			<Route exact path="/advancesearch" element={<AdvanceSearch />} />
			<Route exact path="/advancesearch/:paramName" element={<AdvanceSearch />} />
			<Route exact path="/propose" element={<ProposeTipster />} />
			<Route exact path="/fortipster" element={<ForTipster />} />
			<Route exact path="/user-profile/:id" element={<OtherUserProfile />} />
			<Route exact path="/mobileapp" element={<MobileApp />} />
			{/* {currentUser ? (
        <Route exact path="/" element={<Home  />} />
      ) : (
        <Route exact path="/signin" element={<SignIn  />} />
      )} */}
			<Route exact path="/signin" element={<SignIn />} />
			<Route exact path="/welcome" element={<WelcomeProtected />} />
			<Route exact path="/faqs" element={<Faqs />} />
			<Route exact path="/bettingknowledge" element={<BettingKnowledge />} />
			<Route exact path="/questions" element={<QuestionsPage />} />
			<Route exact path="/menu" element={<Menu />} />
			<Route exact path="/tipster/:ids" element={<TipsterPage />} />
			<Route exact path="/contactus" element={<ContactUs />} />
			<Route exact path="/userprofile" element={<UserProfileRoute />} />
			<Route exact path="*" element={<Notfound />} />
			<Route exact path="/SplashScreen" element={<SplashScreen />} />
			<Route exact path="/aboutus" element={<AboutUs />} />
			<Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route exact path="/terms-conditions" element={<TermsAndConditions />} />
			<Route exact path="/offline-reviews" element={<Reseasoffline />} />
			<Route exact path="/about-tipsterscheck" element={<AboutTipsterscheck />} />
			<Route exact path="/guidelines-reviewers" element={<GuidelinesReviewers />} />

			<Route exact path="/help-writing-reviews" element={<HelpWritingReviews />} />
			<Route exact path="/report-a-review" element={<ReportReview />} />
			<Route exact path="/guidelines-for-tipsters" element={<GuidelinesTipsters />} />
			<Route exact path="/all-resources" element={<AllResources />} />
			<Route exact path="/information-tipster-profiles" element={<InformationTipstersProfile />} />
			<Route exact path="/defamatory-content" element={<DefamatoryContent />} />
			<Route exact path="/factual-disputes" element={<FactualDisputes />} />
			<Route exact path="/betting-knowledge" element={<KnowledgeQuiz />} />
			<Route exact path="/juego-responsable" element={<JuegoResponsable />} />
			<Route exact path="/sports-betting-quiz" element={<SportsBettingQuiz />} />

			<Route exact path="/removeAnyTipster" element={<RemoveAnyTipster />} />

			{/* <Route path="*" element={<p>There's nothing here: 404!</p>} /> */}
		</Routes>
	);
}
