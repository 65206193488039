import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Header from "../../components/Header/header";
import "../../assets/css/style.css";
import "../../App.css";

import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";

export default function FactualDisputes() {
	const { t, i18n } = useTranslation();
	const factualDisputes = t("factualDisputes", { returnObjects: true });
	// const [i, seti] = useState(1);
	// const [j, setj] = useState(1);
	var i = 0;
	var j = 0;

	return (
		<div>
			<Header />
			{/* <div className="container-fluid top-banner">
        <div className="d-flex flex-column justify-content-md-around ms-5">
          <h2 className="h1 fw-bold top-banner">Terms & Conditions</h2>
          <p className="fs-6 text-light">Frequently Asked Questions</p>
        </div>
      </div> */}
      <style>
        {
          `
		  .c12 {
			text-decoration: none;
			vertical-align: baseline;
			font-family: "Arial";
			font-style: normal;
		}
		
		.c9 {
			background-color: #ffffff;
			font-size: 13pt;
			color: #333333;
			font-weight: 700;
		}
		p.c11 {
			margin-bottom: 0;
		}
		.c6 {
			border-right-style: solid;
			padding-top: 0pt;
			border-top-width: 0pt;
			border-right-width: 0pt;
			padding-left: 0pt;
			padding-bottom: 0pt;
			line-height: 1.15;
			border-left-width: 0pt;
			border-top-style: solid;
			background-color: #ffffff;
			border-left-style: solid;
			border-bottom-width: 0pt;
			border-bottom-style: solid;
			orphans: 2;
			widows: 2;
			text-align: left;
			padding-right: 0pt;
		}
          `
        }
      </style>
			<div className="top-banner">
				<div className="container">
					<h2>{t("We take a neutral stance in factual disputes")}</h2>
					{/* <p> Frequently Asked Questions </p> */}
				</div>
			</div>
			{/*  ================= LOWER SECTION ======================== */}
			<div className="container mt-5 gx-3">
				<div className="section-privacy">
					<h2>{t("")}</h2>
					<div className="sub-section-privacy" dangerouslySetInnerHTML={{__html: factualDisputes}}></div>
				</div>
			</div>
			<div className="py-5"></div>
			<Footer />
		</div>
	);
}
